import { type VariantProps, cva } from 'class-variance-authority'

import { cn } from '#utils/utils'

import { Button } from './Button'
import { Icon, type IconName } from './Icon'

const alertVariants = cva(
  '[&>svg]:text-foreground relative flex w-full items-center gap-4 rounded-lg border border-border px-4 py-3',
  {
    variants: {
      variant: {
        default: 'bg-muted text-foreground',
        outline: 'border-input text-foreground bg-transparent',
        destructive:
          'border-destructive/80 text-destructive bg-rose-100  [&>svg]:text-destructive',
        success:
          'border-success-600 text-success-800 [&>svg]:text-success-800 bg-success-100',
        warning:
          'border-warning-200 text-warning-700 [&>svg]:text-warning-800 bg-warning-50'
      }
    },
    defaultVariants: {
      variant: 'default'
    }
  }
)

const Alert = ({
  className,
  variant,
  children,
  icon,
  ...props
}: React.HTMLAttributes<HTMLDivElement> &
  VariantProps<typeof alertVariants> & { icon?: IconName }) => (
  <div className={cn(alertVariants({ variant }), className)} {...props}>
    {icon ? <Icon name={icon} /> : null}
    {children}
  </div>
)

const AlertTitle = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLHeadingElement>) => (
  <h5 className={cn('text-sm leading-snug', className)} {...props} />
)

const AlertDescription = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLParagraphElement>) => (
  <div className={cn('text-xs [&_p]:leading-relaxed', className)} {...props} />
)

const AlertAction = ({
  className,
  ...props
}: React.ComponentProps<typeof Button>) => (
  <Button data-slot="action" className={cn(className)} {...props} />
)

export { Alert, AlertDescription, AlertTitle, AlertAction }
